import { dashboard } from './dashboard';
import { utilities } from './utilities';
import { other } from './other';
import { drive } from './drive';
import { documentation } from './documentation';

//-----------------------|| MENU ITEMS ||-----------------------//

const menuItems = {
    items: [dashboard, drive, documentation]
};

export default menuItems;
