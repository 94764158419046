import { Utulsa_blue, Utulsa_blueV2, Utulsa_grey, Utulsa_white, Spot, Spot_Patriot} from './../../assets/images/index'
//\
//Background object to store what current background is, it is accessed by other elements to determine their colors
const currentBackground = ({
    fontColor: '#b5aeae',
    colorA: '#b5aeae', //Used for top bar
    colorB: '#b5aeae', //Used for left side bar
    colorC: '#b5aeae', //Used for root
    logoSource: Utulsa_white,
    returnBackground: function() {
        return (this)
    },
    changeBackground: function(newA, newB, newC, newLogo, newFont) {
        this.colorA = newA
        this.colorB = newB
        this.colorC = newC
        this.logoSource = newLogo
        this.fontColor = newFont
        return (true);
    },
    });

//Call 'GET' in other elements to use the current color scheme, call other to set the current theme
function changeBackground(colorScheme){
        if (colorScheme === 'GET'){
            return(currentBackground.returnBackground());
        }
        //To add new themes, add an if statement here and reference instructions in Customization/index.js ~line 100
        else if (colorScheme === 'default'){
            currentBackground.changeBackground(
                '#ffffff',
                '#ffffff',
                '#ffffff',
                Utulsa_white,
                '#595959');
            return(currentBackground.returnBackground());
        }
//\
        else if (colorScheme === 'light blue'){
            currentBackground.changeBackground(
                'linear-gradient(-60deg, #1E88E5 -5%, #90CAF9 95%)',
                'linear-gradient(-60deg, #64a5de -5%, #90CAF9 95%)',
                'linear-gradient(-60deg, #107dde -5%, #90CAF9 95%)',
                Utulsa_blue,
                '#000000');
            return(currentBackground.returnBackground());
        }
        else if (colorScheme === 'dark mode'){
            currentBackground.changeBackground(
                'linear-gradient(-60deg, #2e2a2a -5%, #524e4e 95%)',
                'linear-gradient(-60deg, #3d3939 -5%, #524e4e 95%)',
                'linear-gradient(-60deg, #3b3838 -5%, #3b3838 95%)',
                Utulsa_grey,
                '#a1a1a1');
            return(currentBackground.returnBackground());
        }
        else if (colorScheme === 'testing'){
            currentBackground.changeBackground(
                'linear-gradient(-60deg, #a4c2ed -5%, #71a6f0 95%)',
                'linear-gradient(-60deg, #78B0FF -5%, #72A6F0 95%)',
                'linear-gradient(-60deg, #ADCFFF -5%, #71a6f0 95%)',
                Utulsa_blueV2,
                '#595959');
            return(currentBackground.returnBackground());
        }
        else{
            currentBackground.changeBackground(
                '#b5aeae',
                '#b5aeae',
                '#b5aeae',
                Utulsa_white);
            return(currentBackground.returnBackground());
        }

};
export default changeBackground;