import { IconBrandFramer, IconTypography, IconPalette, IconShadow, IconWindmill, IconLayoutGridAdd, IconSteeringWheel,IconHeartHandshake, IconRobot, IconDeviceComputerCamera} from '@tabler/icons';

// constant
const icons = {
    IconTypography: IconTypography,
    IconPalette: IconPalette,
    IconShadow: IconShadow,
    IconWindmill: IconWindmill,
    IconBrandFramer: IconBrandFramer,
    IconLayoutGridAdd: IconLayoutGridAdd,
    IconSteeringWheel: IconSteeringWheel,
    IconHeartHandshake: IconHeartHandshake,
    IconRobot: IconRobot,
    IconDeviceComputerCamera: IconDeviceComputerCamera
};
export const drive = {
    id: 'robotic',
    title: 'Robotics',
    type: 'group',
    children:[
        {
        id: 'drive',
        title: 'Drive',
        type: 'collapse',
        icon: icons['IconSteeringWheel'],
        children: [
            {
                id: 'Hopper',
                title: 'Hopper',
                type: 'item',
                url: '/dashboard/drive/Hopper',
                icon: icons['IconHeartHandshake'],
                breadcrumbs: false
            },
            {
                id: 'Krypto',
                title: 'Krypto',
                type: 'item',
                url: '/dashboard/drive/Krypto',
                icon: icons['IconDeviceComputerCamera'],
                breadcrumbs: false
            },
            {
                id: 'Hash',
                title: 'Hash',
                type: 'item',
                url: '/dashboard/drive/Hash',
                icon: icons['IconRobot'],
                breadcrumbs: false
            }
        ]
    },
    {
        id: 'SpyDogs',
        title: 'SpyDogs',
        type: 'item',
        url: '/dashboard/drive/SpyDogs',
        icon: icons['IconShadow'],
        breadcrumbs: false
    }
    ]
}