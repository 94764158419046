export const Battery0 = require('./Battery0.png')
export const Battery10 = require('./Battery10.png')
export const Battery20 = require('./Battery20.png')
export const Battery30 = require('./Battery30.png')
export const Battery40 = require('./Battery40.png')
export const Battery50 = require('./Battery50.png')
export const Battery60 = require('./Battery60.png')
export const Battery70 = require('./Battery70.png')
export const Battery80 = require('./Battery80.png')
export const Battery90 = require('./Battery90.png')
export const Battery100 = require('./Battery100.png')
export const BatteryErr = require('./BatteryErr.png')
export const batteryoff = require('./batteryoff.png')
export const check = require('./check.webp')
export const Utulsa_white = require('./Utulsa-default.png')
export const Utulsa_blue = require('./Utulsa-light-blue.png')
export const Utulsa_grey = require('./Utulsa-dark.png')
export const Utulsa_blueV2 = require('./Utulsa-light-blue-v2.png')
export const Spot = require('./Spot.png')
export const Spot_Patriot = require('./Spot Patriotic.png')